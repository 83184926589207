import React, { useEffect, useState } from "react";
import HeaderHome from "../headers/headerHome";
import '../css/Prog.css';
function Programmation(){
	const concerts = [
		{ id: 1, date: '2024-11-10', artist: 'The Rolling Stones', location: 'Paris, France' },
		{ id: 2, date: '2024-12-05', artist: 'Taylor Swift', location: 'London, UK' },
		{ id: 3, date: '2025-01-18', artist: 'Coldplay', location: 'New York, USA' },
		{ id: 4, date: '2025-02-23', artist: 'Beyoncé', location: 'Berlin, Germany' }
	  ];
	return (
		<>
		<HeaderHome />
			<div className="concert-program">
				<h1>Evènements à venir</h1>
				<div className="concert-list">
					{concerts.map(concert => (
					<div key={concert.id} className="concert-card">
						<h2>{concert.artist}</h2>
						<p>Date: <span>{concert.date}</span></p>
						<p>Lieu: <span>{concert.location}</span></p>
					</div>
					))}
				</div>
			</div>
		</>
	);
}


export default Programmation;