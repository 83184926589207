import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import ScrollToTop from "../hooks/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
// import ProtectedApp from "./ProtectedApp";
import Home from "./pages/Home";
import Galerie from './pages/Galerie';
import Programmation from './pages/Programmation';
import Login from './pages/Login';
import ProtectedApp from "./components/ProtectedApp";
import { UserContextProvider } from "./userContext/userContext";


function App() {
  const queryClient = new QueryClient();
  // Used to keep connexion alive (using page refresh)
  
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/galerie" component={Galerie} />
          <Route exact path="/prog" component={Programmation} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Login} />
          {/* <Route exact path="/initpassword/:src" component={ReinitPassword} /> */}
          <QueryClientProvider client={queryClient}>
            <ProtectedApp />
          </QueryClientProvider>
        </Switch>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
