import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import * as API from "../services/API";
import Icon from "../util/Icon";
import { Modal, Button } from "react-bootstrap";

// import Card from "../components/card";

// Modal Component
const MyModal = ({ onClose,onAdd,member = null,isUpdate }) => {
	console.log(member)
	const [_id, set_id] = useState(member?member._id:null);
	const [username, setUsername] = useState(member?member.username:null);
	const [password, setPassword] = useState(null);
	const [last_name, setLast_name] = useState(member?member.last_name:null);
	const [fonction, setFonction] = useState(member?member.function:null);
	const [first_name, setFirst_name] = useState(member?member.first_name:null);
	const [role, setRole] = useState(member?member.role:1);
	const [lang, setLang] = useState(member?member.lang:"fr");
	const [createdAt] = useState(member?member.createdAt:null);
	const [phone] = useState(member?member.phone:null);
	const [updatedAt] = useState(member?member.updatedAt:null);
	const [isDeleted] = useState(member?member.isDeleted:null);
	const [email,setEmail] = useState(member?member.email:null);

	// const [description, setDescription] = useState("");
	return (
	<Modal
        show={true}
        onHide={onClose}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Création de Membre
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
			<label htmlFor="username">Username</label>
			<input
				type={"text" }
				className="form-control m-2"
				id="username"
				value={username}
				onChange={(e)=>setUsername(e.target.value)}
				name="username"
			/>
			<label htmlFor="Password">Mot de passe</label>
			<input
				type={"password" }//TODO REGEX
				className="form-control m-2"
				id="Password"
				value={password}
				onChange={(e)=>setPassword(e.target.value)}
				name="Password"/>
			<label htmlFor="last_name">Nom</label>
			<textarea 
				type={"text" }
				className="form-control m-2"
				id="last_name"
				rows="1" 
				maxlength="50"
				value={last_name}
				onChange={(e)=>setLast_name(e.target.value)}
				name="last_name"
			/>
			<label htmlFor="first_name">Prénom</label>
			<input
				type={"text" }
				className="form-control m-2"
				id="first_name"
				value={first_name}
				onChange={(e)=>setFirst_name(e.target.value)}
				name="first_name"
			/>
			<label htmlFor="email">Email</label>
			<textarea 
				type={"text" }
				className="form-control m-2"
				id="email"
				rows="1" 
				maxlength="50"
				value={email}
				onChange={(e)=>setEmail(e.target.value)}
				name="email"
			/>
			<label htmlFor="Roles">Role</label>
			<select
				name="Roles" 
				className="form-control m-2"
				id="Roles"
				onChange={(e)=>setRole(e.target.value)}
				defaultValue={role}

			>
				<option value="1">Membre</option>
				<option value="2">Admin</option>
			</select>
			
			<label htmlFor="Fonction">Fonction</label>
			<input
				type={"text" }
				className="form-control m-2"
				id="Fonction"
				value={fonction}
				onChange={(e)=>setFonction(e.target.value)}
				name="Fonction"
			/>
			<label htmlFor="Langage">Langage</label>
			<select
				name="Langage" 
				className="form-control m-2"
				id="Langage"
				onChange={(e)=>setLang(e.target.value)}
				defaultValue={lang}
			>
				<option value="fr">Français</option>
				<option value="en">Anglais</option>
			</select>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
		  <Button variant="primary" onClick={()=>{onAdd({_id:_id,username:username,password:password,last_name:last_name,first_name:first_name,role:role,function:fonction,lang:lang,createdAt:createdAt,phone:phone,updatedAt:updatedAt,isDeleted:isDeleted,email:email})}}>
            {isUpdate?"Modifier":"Ajouter"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
}	

function MembresAdmin(){
	const [modalOpen, setModalOpen] = useState(null);
	const [MembersList, setMembersList] = useState([]);
	useEffect(() => {
		API.getAllMembersRequest(
			(response)=>{
				setMembersList(response.data);
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);
	const addMember = (data) => {API.createMemberRequest(data,()=>{API.getAllMembersRequest((response)=>{setMembersList(response.data);},(data)=>console.error(data))},(data)=>{console.error(data)})};
	const updateMember = (data) => {API.updateMemberRequest(data,()=>{setModalOpen(null);API.getAllMembersRequest((response)=>{setMembersList(response.data);},(data)=>console.error(data))},(data)=>{console.error(data)})};
	const openAddModal = () => setModalOpen(<MyModal onClose={closeModal} onAdd={addMember}/>);
	const openUpdateModal = (member) => setModalOpen(<MyModal onClose={closeModal} onAdd={updateMember} member={member} isUpdate={true}/>);

	const closeModal = () => setModalOpen(null);

		let action = (a)=>(<>
			<Icon  size="xl"
			onClick={() =>
				openUpdateModal(a)
			}
			 icon="pen-to-square" clickable  />
		<Icon  size="xl"
			onClick={(e) =>{
				API.deleteMemberRequest(a._id,()=>{API.getAllMembersRequest(
					(response)=>{
						setMembersList(response.data);
					},
					()=>{
						console.log("err");
					}
					)
				},()=>{});
			}
			}
			 icon="trash" clickable  />
		</>);
	return (
		<>
            <div className="h-100">
			    <HeaderHomeAdmin />
				<h1 className="text-center m-5 text-dark">Liste des membres :</h1>
				<button className="m-5 btn btn-light" onClick={openAddModal}>Créer un utilisateur</button>
				<div className={"d-flex justify-content-center h-75 w-100"}>
					<div className="d-flex justify-content-center h-50 w-75 m-5 rounded">
					<table className="table table-striped table-bordered">
						<thead className="thead-light">
							<tr>
							<th className="col col-2">Login</th>
							<th className="col col-5">Nom</th>
							<th className="col col-2">Prénom</th>
							<th className="col col-2">Numéro de téléphone</th>
							<th className="col col-2">Email</th>
							<th className="col col-2">Action</th>
							</tr>
						</thead>
						<tbody>
				{(MembersList && MembersList.length > 0 )?MembersList.map((member)=>{
								return <tr><td className="col col-2">{member.username}</td>
								<td className="col col-5">{member.first_name}</td>
								<td className="col col-2">{member.last_name}</td>
								<td className="col col-2">{member.phone}</td>
								<td className="col col-2">{member.email}</td>
								<td className="col col-2">{action(member)}</td></tr>;
							}):<></>}
							</tbody>
							</table>
					</div>
				</div>
				{modalOpen}
            </div>
		</>
	);
}


export default MembresAdmin;