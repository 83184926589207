import React, { useEffect, useState } from "react";
import HeaderHome from "../headers/headerHome";
import ActiviteCard from "../components/card";
import * as API from "../services/API";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
function Home(){
	const [modalOpen, setModalOpen] = useState(null);
	const [activityList, setActivityList] = useState([]);
	useEffect(() => {
		API.getActiviteRequest(
			(response)=>{
				setActivityList(response.data);
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);
		console.log(activityList)
	return (
		<>
            <div className="h-100">
			    <HeaderHome />
				<h1 className="text-center my-5 text-light">Loisirs et Solidarité des retraités 47</h1>
				<div className="d-flex justify-content-center my-5 h-100">
					<div className="h-75 border border-light rounded w-75 border-3 p-4 bg-dark">
						<Row xl={2} className="g-4">
							{
								activityList.map((data,idx)=>{
										return <><Col key={idx}><ActiviteCard onClick={()=>{console.log("test")}} data={data}></ActiviteCard></Col></>;
								})
							}
						</Row>
					</div>
				</div>
            </div>
		</>
	);
}


export default Home;