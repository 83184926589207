import React, { useEffect, useState } from "react";
import HeaderHome from "../headers/headerHome";
import * as API from "../services/API";
import { Modal } from 'react-bootstrap'; // Assurez-vous d'avoir bootstrap installé


function Galerie(){
	const [GalerieList, setGalerieList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

 // Fonction pour ouvrir la modal avec l'image sélectionnée
 const openImageModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  // Fonction pour fermer la modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };
	useEffect(() => {
		API.getPublicGalerieRequest(
			(response)=>{
				setGalerieList(response.data.data);
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);
	return (
		<>
            <div className={"h-100"}>
			    <HeaderHome /> 
				<div>
					<h2 className="text-center m-5 text-light">Galerie</h2>
					<div className="container">
				<div className="row">
					{GalerieList.map((image, index) => (
							<div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
							onClick={() => openImageModal(image)} // Ouvrir l'image dans la modal au clic
              				style={{ cursor: 'pointer' }}>
								<img
									src={"data:image/png;base64," + image.base64Image}
									alt={`Galerie Image ${index}`}
									className="img-fluid rounded shadow"
									style={{ objectFit: "cover", height: "200px", width: "100%" }}
								/>
							</div>
						))}
					</div>
				</div>
				</div>
            </div>
			{/* Modal Bootstrap pour afficher l'image en grand */}
		<Modal show={showModal} onHide={closeModal} centered>
			<Modal.Header closeButton>
				<Modal.Title>Image en grand</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{selectedImage && (
				<img
				src={"data:image/png;base64," + selectedImage.base64Image}
				alt="Image en grand"
				className="img-fluid"
				style={{ width: '100%' }}
				/>
			)}
			</Modal.Body>
		</Modal>

      
		</>
	);
}


export default Galerie;