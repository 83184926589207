import Card from 'react-bootstrap/Card';
import { useTranslation } from "react-i18next";

function ActiviteCard({openModal,onUnsubscribe,onSubscribe,isListed,user,data}) {
  const { t } = useTranslation("common");
  if(data){
  return (
    <Card className='h-100 m-4 border rounded-3 bg-light shadow-sm'>
    <Card.Body>
      <Card.Title className="text-center text-primary">{data.title}</Card.Title>
      <Card.Text className="text-center">{data.description}</Card.Text>
    </Card.Body>
    <Card.Footer className="d-flex justify-content-between align-items-center">
      <small className="text-muted">
        {new Date(data.start_date).toLocaleDateString('fr-FR')}
      </small>
      
      {(user && user.role) && (
        <>
        <button
          type="submit"
          className={`btn ${isListed ? 'btn-danger' : 'btn-success'}`}
          onClick={() => {
            if (isListed) {
              onUnsubscribe({ activity_id: data._id });
            } else {
              onSubscribe({ activity_id: data._id });
            }
          }}
        >
          {isListed ? t("users.__unsubscribeBtn__") : t("users.__subscribeBtn__")}
        </button>
        <button
          className="btn btn-info"
          onClick={()=> {console.log("open2");openModal();}}
          >
            Liste inscrits
          </button>
      </>
      )}
      
      <small className="text-muted">
        {new Date(data.end_date).toLocaleDateString('fr-FR')}
      </small>
    </Card.Footer>
  </Card>
  );
}else{
  return (<></>)
}
}

export default ActiviteCard;
