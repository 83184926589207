import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
function HeaderHome() {
  return (
    <>
    <div className='App'>
        <Navbar bg="dark" variant="dark" className="justify-content-center">
          <Container className='row'>
            
            <Nav>
              <Nav.Link className='col' href="/home">Accueil</Nav.Link>
              <Nav.Link className='col' href="/galerie">Galerie</Nav.Link>
              <Nav.Link className='col' href="/prog">Programmation</Nav.Link>
              {JSON.parse(sessionStorage.getItem("isLogged")) === true && <Nav.Link className='col' href="/signup">Inscription</Nav.Link>}
              <Nav.Link className='col' href="/login">Espace adhérants</Nav.Link>
              {JSON.parse(sessionStorage.getItem("isLogged")) === true && <Nav.Link className='col' onClick={()=>sessionStorage.setItem("isLogged", JSON.stringify(false))} href="/">Deconnexion</Nav.Link>}
            </Nav>
          </Container>
        </Navbar>
    </div>
    </>
  );
}

export default HeaderHome;
