import React, { useContext,useEffect, useState } from "react";
import HeaderMembers from "../headers/headerMembers.js";
import ActiviteCard from "../components/card";
import * as API from "../services/API";
import { UserContext } from "../userContext/userContext.js";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../css/ActivityMembers.css';

function ActivitesMembers(){
	const [showModal, setShowModal] = useState(false);
	const [activityList, setActivityList] = useState([]);
	const [users, setUsers] = useState([]);
	const [selectedActivity, setSelectedActivity] = useState(null);
  // Fonction pour ouvrir la modale
  const handleOpenModal = (data) => {
	console.log("handleOpenModal");
	setSelectedActivity(data);
	console.log(selectedActivity);
	setShowModal(true);
  };

  // Fonction pour fermer la modale
  const handleCloseModal = () => {
	console.log("handleCloseModal");
	setSelectedActivity(null)
	setShowModal(false);
  }
    const {
		userState,
		userDispatch,
	} = useContext(UserContext);
    console.log(userState.userConnected);
	useEffect(() => {
		API.getAllMembersByRoleRequest(
			(response)=>{
				setUsers(response.data);
			},
			()=>{
				console.log("err");
			}
		);
		API.getActiviteRequest(
			(response)=>{
				setActivityList(response.data);
			},
			()=>{
				console.log("err");
			}
			);
		},
		[]
		);

    const isListed = (data)=>{
        if(data.subscription){
            for(let i of data.subscription){
                if(i.member_id === userState.userConnected._id){
                    return true;
                }
            }
            return false;
        }else{
            return false;
        }
    }
    const subscribe = (data)=>{
        let dataobject = {
            member_id: userState.userConnected._id,
            activity_id: data.activity_id
        }
        API.subscribeActiviteRequest(dataobject,
			(response)=>{
				API.getActiviteRequest(
					(response)=>{
						setActivityList(response.data);
					},
					()=>{
						console.log("err");
					}
				)
			},
			()=>{
				console.log("err");
			}
			)
    }
    const unsubscribe = (data)=>{
        let dataobject = {
            member_id: userState.userConnected._id,
            activity_id: data.activity_id
        }
        API.unsubscribeActiviteRequest(dataobject,
			(response)=>{
				API.getActiviteRequest(
					(response)=>{
						setActivityList(response.data);
					},
					()=>{
						console.log("err");
					}
				)
			},
			()=>{
				console.log("err");
			}
			)
    }
	console.log(activityList)
	if(activityList === null){
		return (<></>)
	}
	return (
		<>
            <div className="h-100">
			    <HeaderMembers />
				<h1 className="text-center m-5 text-light">Loisirs et Solidarité des retraités 47</h1>
				<div className="d-flex justify-content-center m-5 h-100">
					<div className="h-75 border border-light rounded w-75 border-3">
						<Row xl={2} className="g-2">
							{
								activityList.map((data,idx)=>{
										return <><Col className="mb-5" key={idx}><ActiviteCard openModal={()=>handleOpenModal(data._id)}  onUnsubscribe={()=>{unsubscribe({activity_id: data._id});console.log("test")}} onSubscribe={()=>{subscribe({activity_id: data._id});console.log("test")}} isListed={isListed(data)} user={userState.userConnected} data={data}></ActiviteCard></Col></>;
								})
							}
						</Row>
					</div>
				</div>
            </div>
			{/* Modale pour afficher la liste des inscrits */}
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Liste des inscrits</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{users.length > 0 ? (
					<ul>
					{users.filter((u) => {
						const activity = activityList.filter((a) => a._id === selectedActivity)[0];
						return activity?.subscription.some((s) => s.member_id === u._id);
						}).map((participant, idx) => (
						<li key={idx}>
							{participant.first_name + " " + participant.last_name}
						 <span className="participant-email">{participant.email}</span>
						 </li>
					))}
					</ul>
				) : (
					<p>Aucun inscrit pour le moment.</p>
				)}
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleCloseModal}>
					Fermer
				</Button>
				</Modal.Footer>
      		</Modal>
		</>
	);
}


export default ActivitesMembers;