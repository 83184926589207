import React, { useState,useEffect } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import { Modal, Button } from 'react-bootstrap'; // Importer Modal et Button depuis react-bootstrap
import '../css/Prog.css';
import * as API from "../services/API";

function ProgrammationAdmin() {
  const [concerts, setConcerts] = useState([]);

  const [newConcert, setNewConcert] = useState({ date: '', artist: '', location: '' });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    API.getProgrammationRequest(
        (response)=>{
            setConcerts(response.data);
        },
        ()=>{
            console.log("err");
        }
    );
    },
    []
    );

    const handleDelete = (id) => {
        // Filtrer la liste des concerts pour supprimer celui correspondant à l'ID
        
        API.removeProgrammationRequest({_id: id},()=>{
            API.getProgrammationRequest((response)=>{
                setConcerts(response.data);
            },()=>{console.error("failed pull")})
        },()=>{
            console.error("failed create")
        })
        // Mettre à jour l'état avec la nouvelle liste de concerts
      };
  // Ajouter un nouveau concert
  const addConcert = () => {
    if (newConcert.artist && newConcert.date && newConcert.location) {
    //   setConcerts([...concerts, { ...newConcert, id: Date.now() }]);
    //   setNewConcert({ date: '', artist: '', location: '' });
      API.createProgrammationRequest({newConcert},()=>{
        API.getProgrammationRequest((response)=>{
            setConcerts(response.data);
            setNewConcert({ date: '', artist: '', location: '' });
        },()=>{console.error("failed pull")})
      },()=>{console.error("failed create")})
      setShowModal(false); // Fermer la modale après ajout
    }
  };

  return (
    <>
      <HeaderHomeAdmin />
      <div className="concert-program">
        <h1>Evènements à venir</h1>
        <Button variant="primary" onClick={() => setShowModal(true)}>Créer un évènement</Button>
        <div className="concert-list">
          {concerts.map(concert => (
            <div key={concert._id} className="concert-card">
              <h2>{concert.artist}</h2>
              <p>Date: <span>{concert.date}</span></p>
              <p>Lieu: <span>{concert.location}</span></p>
              <Button variant="danger" onClick={() => handleDelete(concert._id)}>Supprimer</Button>
            </div>
          ))}
        </div>
      </div>

      {/* Modale pour ajouter un concert */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouveau concert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Artiste"
            value={newConcert.artist}
            onChange={(e) => setNewConcert({ ...newConcert, artist: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="date"
            placeholder="Date"
            value={newConcert.date}
            onChange={(e) => setNewConcert({ ...newConcert, date: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Lieu"
            value={newConcert.location}
            onChange={(e) => setNewConcert({ ...newConcert, location: e.target.value })}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={addConcert}>
            Ajouter le concert
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProgrammationAdmin;
