import React, { createContext, useState, useEffect, useReducer } from "react";
import UserReducer from "../reducers/UserReducer";
import User from "../models/UserClass";

const initialUserState = {
	isLogged: JSON.parse(sessionStorage.getItem("isLogged")) || false,
	userConnected:
		new User(
			JSON.parse(localStorage.getItem("firstName")),
			JSON.parse(localStorage.getItem("lastName")),
			JSON.parse(localStorage.getItem("role")),
			JSON.parse(localStorage.getItem("_id")),
		) || null
};



export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
	const [userState, userDispatch] = useReducer(UserReducer, initialUserState);


	return (
		<UserContext.Provider
			value={{
				userState,
				userDispatch,
			}}>
			{children}
		</UserContext.Provider>
	);
};

export const UserContextConsumer = UserContext.Consumer;
