import React, { useCallback, useMemo } from "react";

import { Redirect } from "react-router-dom";


export default function PageLayout({
	userState,
	userDispatch,
	children
}) {
	// const isAdmin = useMemo(() => userState.userConnected.is(p.ADMIN), []);

	const redirectToLogin = useCallback(() => {
		return <Redirect to="/" />;
	}, []);
	console.log(userState.userConnected.role);
	return userState.isLogged ? (
		<div className="page-layout">
			{children}
		</div>
	) : (
		redirectToLogin()
	);
}
