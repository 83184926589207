import User from "../models/UserClass";

const UserReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			return {
				...state,
				isLogged: true,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.role,
					action.payload._id,
				)
			};
		case "LOGOUT":
			return {
				...state,
				isLogged: false
			};

		default:
			return state;
	}
};

export default UserReducer;
