class User {
	constructor(first_name, last_name, role, _id) {
		this.first_name = first_name;
		this.last_name = last_name;
		this._id = _id;
		this.role = role;
	}

	// is(permission) {
	// 	if (this.permissions.has(permission)) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	// is_in(permissionsNeeded) {
	// 	if (permissionsNeeded.length > 0) {
	// 		for (const p of permissionsNeeded) {
	// 			if (this.permissions.has(p)) {
	// 				return true;
	// 			}
	// 		}
	// 	}
	// 	return false;
	// }
}

export default User;
