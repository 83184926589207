import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import "../css/Table.scss";
import { Modal, Button } from "react-bootstrap";
// import Card from "../components/card";
import Icon from "../util/Icon";
import ModalManager from "../util/ModalManager";
import * as API from "../services/API";


// Modal Component
const MyModal = ({ onClose,onAdd,activite = null }) => {
	const [title, setTitle] = useState(activite?activite.title:null);
	const [description, setDescription] = useState(activite?activite.description:null);
	const [endDate, setEndDate] = useState(activite?new Date(activite.end_date):null);
	const [startDate, setStartDate] = useState(activite?new Date(activite.start_date):null);
	// const [description, setDescription] = useState("");
	return (
	<Modal
        show={true}
        onHide={onClose}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Création d'activité
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
			<label htmlFor="title">Title</label>
			<input
				type={"text" }
				className="form-control m-2"
				id="title"
				value={title}
				onChange={(e)=>setTitle(e.target.value)}
				name="title"
			/>
			<label htmlFor="description">Description</label>
			<textarea 
				type={"text" }
				className="form-control m-2"
				id="description"
				rows="5" 
				maxlength="1200"
				value={description}
				onChange={(e)=>setDescription(e.target.value)}
				name="description"
			/>
			<label htmlFor="startdate">Start Date</label>
			<input
				type={"date" }
				className="form-control m-2"
				id="setStartDate"
				value={startDate}
				onChange={(e)=>setStartDate(e.target.value)}
				name="setStartDate"
			/>
			<label htmlFor="endDate">End Date</label>
			<input
				type={"date" }
				className="form-control m-2"
				id="endDate"
				value={endDate}
				onChange={(e)=>setEndDate(e.target.value)}
				name="endDate"
			/>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
		  <Button variant="primary" onClick={()=>onAdd({title:title,description:description,start_date:startDate,end_date:endDate})}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    );
}	

function ActivitesAdmin(){
	const [modalOpen, setModalOpen] = useState(null);
	const [activityList, setActivityList] = useState([]);
	useEffect(() => {
		API.getAllActiviteRequest(
			(response)=>{
				setActivityList(response.data);
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);
	const addActivite = (data) => {API.CreateActiviteRequest(data,()=>{API.getAllActiviteRequest((response)=>{setActivityList(response.data);closeModal();},(data)=>{console.error(data);closeModal();})},(data)=>{console.error(data)})};
	const openAddModal = () => setModalOpen(<MyModal onClose={closeModal} onAdd={addActivite}/>);
	const openUpdateModal = (activite) => setModalOpen(<MyModal onClose={closeModal} onAdd={addActivite} activite={activite}/>);
	const closeModal = () => setModalOpen(null);
	let action = (a)=>(<>
					<Icon  size="xl"
					onClick={(e) =>
						console.log("test")
					}
             		icon="eye" clickable  />
					<Icon  size="xl"
					onClick={(e) =>
						openUpdateModal(a)
					}
             		icon="pen-to-square" clickable  />
				<Icon  size="xl"
					onClick={(e) =>{
						API.deleteActiviteRequest(a._id,()=>{API.getAllActiviteRequest(
							(response)=>{
								setActivityList(response.data);
							},
							()=>{
								console.log("err");
							}
							)
						},()=>{});
					}
					}
             		icon="trash" clickable  />
				</>);


	return (
		<>
            <div className="h-100 bg-secondary bg-gradient">
			    <HeaderHomeAdmin />
				<h1 className="text-center m-5 text-light">Loisirs et Solidarité des retraités 47</h1>

      			<button className="btn btn-light" onClick={openAddModal}>Créer une activité</button>
				<div className={"d-flex justify-content-center h-75 w-100"}>
					<div className="d-flex justify-content-center h-50 w-75 m-5 rounded">
					<table className="table table-striped table-bordered">
						<thead className="thead-light">
							<tr>
							<th className="col col-2">Titre</th>
							<th className="col col-5">Description</th>
							<th className="col col-2">Start Date</th>
							<th className="col col-2">End Date</th>
							<th className="col col-2">Action</th>
							</tr>
						</thead>
						<tbody>
							{(activityList && activityList.length > 0 )?activityList.map((activite)=>{
								return <tr><td className="col col-2">{activite.title}</td>
								<td className="col col-5">{activite.description}</td>
								<td className="col col-2">{activite.start_date}</td>
								<td className="col col-2">{activite.end_date}</td>
								<td className="col col-2">{action(activite)}</td></tr>;
							}):<></>}
							
						</tbody>
						</table>
					</div>
				</div>
				{modalOpen}
            </div>
		</>
	);
}


export default ActivitesAdmin;