import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
function HeaderHomeAdmin() {
  return (
    <>
    <div className='App'>
        <Navbar bg="dark" variant="dark" className="justify-content-center">
          <Container className='row'>
            {/* className='col col-6' */}
            <Nav >
              {/* <Nav.Link className='col' href="/admin/home">Accueil</Nav.Link>
              <Nav.Link className='col' href="/admin/galerie">Galerie</Nav.Link> */}
              {/* <Nav.Link className='col' href="/prog">Programmation</Nav.Link> */}
              {JSON.parse(sessionStorage.getItem("isLogged")) === false &&<Nav.Link href="/login">Espace CA</Nav.Link>}
              <Nav.Link className='col' href="/admin/gestion">Gestion</Nav.Link>
              <Nav.Link className='col' href="/admin/statistique">Statistique</Nav.Link>
              {/* {JSON.parse(sessionStorage.getItem("isLogged")) === true && <Nav.Link className='col' href="/admin/signup">Inscription</Nav.Link>} */}
              {JSON.parse(sessionStorage.getItem("isLogged")) === true && <Nav.Link  onClick={()=>sessionStorage.setItem("isLogged", JSON.stringify(false))} href="/">Deconnexion</Nav.Link>}
            </Nav>
          </Container>
        </Navbar>
    </div>
    </>
  );
}

export default HeaderHomeAdmin;
