import React from "react";
import { useTranslation } from "react-i18next";
import "./Loader.scss";

const Loader = () => {
	const { t } = useTranslation("common");

	return (
		<div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
			<div style={{ marginRight: "1rem" }}>
				<div className="dots"></div>
			</div>
			<p>{t("common.__loading__")}</p>
		</div>
	);
};

export default Loader;
