import React, { useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "../userContext/userContext.js";
import PageLayout from "../layout/PageLayout";

// import Login from "../pages/Login";
import HomeAdmin from "../pages/HomeAdmin";
import GestionAdmin from "../pages/GestionAdmin";
import StatistiqueAdmin from "../pages/StatistiqueAdmin";
import GalerieAdmin from "../pages/GalerieAdmin";
import MembresAdmin from "../pages/MembresAdmin";
import ProgrammationAdmin from "../pages/ProgrammationAdmin";
import DemandesAdmin from "../pages/DemandesAdmin";
import ActivitesAdmin from "../pages/ActivitesAdmin";
import ActivitesMembers from "../pages/ActivitesMembers";
import GalerieMembers from "../pages/GalerieMembers"
import ContactMembers from  "../pages/ContactMembers"

const ProtectedApp = () => {
	const {
		userState,
		userDispatch,
	} = useContext(UserContext);
	console.log(userState.userConnected.role);
	return (
		<Switch>
			{/* <Route exact path="/" component={Login} /> */}
			

			{(userState && userState.userConnected && userState.userConnected.role === 2) && <PageLayout
				userState={userState}
				userDispatch={userDispatch}>
				<Route exact path="/admin/home" component={HomeAdmin}/>
				<Route exact path="/admin/gestion" component={GestionAdmin}/>
				<Route exact path="/admin/statistique" component={StatistiqueAdmin}/>
				<Route exact path="/admin/gestion/membres" component={MembresAdmin}/>
				<Route exact path="/admin/gestion/prog" component={ProgrammationAdmin}/>
				<Route exact path="/admin/gestion/galerie" component={GalerieAdmin}/>
				<Route exact path="/admin/activites" component={ActivitesAdmin}/>
			</PageLayout>}
			{(userState && userState.userConnected && userState.userConnected.role === 1) &&<PageLayout
				userState={userState}
				userDispatch={userDispatch}>
				<Route exact path="/ca/home" component={ActivitesMembers}/>
				<Route exact path="/ca/gestion" component={GestionAdmin}/>
				<Route exact path="/ca/galerie" component={GalerieMembers}/>
				<Route exact path="/ca/contact" component={ContactMembers}/>
				<Route exact path="/ca/activites" component={ActivitesMembers}/>
			</PageLayout>}
			
		</Switch>
	);
};

export default ProtectedApp;
