import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import Card from "../components/card";

function HomeAdmin(){
	return (
		<>
            <div className="h-100">
                <HeaderHomeAdmin />
				<h1 className="text-center m-5 text-light">Loisirs et Solidarité des retraités 47</h1>
				<div className="d-flex justify-content-center m-5 h-100">
					<div className="h-50 border border-light rounded w-50 border-3">
						<div className='d-flex h-50 w-100'>
							<Card></Card>
							<Card></Card>
							<Card></Card>
							<Card></Card>
						</div>
						<div className='d-flex h-50 w-100'>
							<Card></Card>
							<Card></Card>
							<Card></Card>
							<Card></Card>
						</div>
					</div>
				</div>
            </div>
		</>
	);
}


export default HomeAdmin;