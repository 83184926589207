import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import { Button } from "react-bootstrap";
// import Card from "../components/card";
import { useHistory } from "react-router-dom";

function GestionAdmin(){
    const history = useHistory();

	return (
		<>
            <div className="h-100" style={{backgroundColor:"#D3D3D3"}}>
			    <HeaderHomeAdmin />
				<h2 className="text-center m-5 text-dark">Gestion</h2>
				<div className={"d-flex justify-content-center h-75 w-100"}>
					<div className="d-flex justify-content-center h-50 w-25 m-5 border border-secondary rounded" style={{backgroundColor:"#7EC8E3"}}>
                        <div className="d-grid gap-1">
                            <Button className="m-auto" variant="primary" size="xl" onClick={()=>{history.push("/admin/gestion/membres");}}>
                                Membres
                            </Button>
                            <Button className="m-auto" variant="primary" size="xl" onClick={()=>{history.push("/admin/gestion/galerie");}}>
                                Galerie
                            </Button>
                            <Button className="m-auto" variant="primary" size="xl" onClick={()=>{history.push("/admin/gestion/prog");}}>
                                Programmation
                            </Button>
                            <Button className="m-auto" variant="primary" size="xl" onClick={()=>{history.push("/admin/activites");}}>Activités</Button>

                        </div>
                    </div>
                </div>
            </div>
		</>
	);
}


export default GestionAdmin;