import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";

// import Card from "../components/card";

function StatistiqueAdmin(){
	return (
		<>
            <div className="h-100 bg-danger">
			    <HeaderHomeAdmin />
				<h1 className="text-center m-5 text-light">Loisirs et Solidarité des retraités 47</h1>
				
            </div>
		</>
	);
}


export default StatistiqueAdmin;